<div [ngStyle]="{backgroundColor: color}" class="main container-fluid">
  <div class="row">
    <div class="col-sm">
      <button type="button" class="btn-lg btn-success btn-width" (click)="green()">Green</button>
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-sm">
      <button type="button" class="btn-lg btn-warning btn-width" (click)="yellow()">Yellow</button>
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-sm">
      <button type="button" class="btn-lg btn-danger btn-width" (click)="red()">Red</button>
    </div>
  </div>
  <div class="row top-buffer justify-content-center">
    <div class="col-12">
      <button type="button" class="btn-lg btn-dark btn-width" (click)="timeout()">Timeout</button>
    </div>
    <div class="col-auto time" >
      {{time}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-sm">
      <button type="button" class="btn-lg btn-primary btn-width" (click)="startStop()">{{timerLabel}}</button>
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-sm">
      <button type="button" class="btn-lg btn-primary btn-width" (click)="resetAll()">Reset</button>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'tc';
  default: '#454545';


  color: string;
  outOfTime: Subscription;

  started: number;
  pausedTime: number;
  time: string;
  timer: Subscription;
  timerLabel: string;

  ngOnInit(): void {
    this.outOfTime = null;
    this.color = this.default;
    this.timerLabel = 'Start';
    this.pausedTime = 0;
    this.time = '00:00';
  }

  green() {
    this.resetTimeout();
    this.setColor('green');
  }

  yellow() {
    this.resetTimeout();
    this.setColor('yellow');
  }

  red() {
    this.resetTimeout();
    this.setColor('red');
  }

  timeout() {
    if (!this.outOfTime) {
      this.outOfTime = interval(1000).subscribe((count) => {
        this.color = count % 2 ? 'red' : this.default;
      });
    } else {
      this.resetColors();
    }
  }

  private resetColors() {
    this.resetTimeout();
    this.color = this.default;
  }

  private resetTimeout() {
    if (this.outOfTime) {
      this.outOfTime.unsubscribe();
      this.outOfTime = null;
    }

  }

  private setColor(color: string) {
    this.color = this.color === color ? this.default : color;
  }

  private leadingZeros(num: number): string {
    let str = num.toString();
    if (num < 10) {
      str = `0${num}`;
    }
    return str;
  }

  private stopTime() {
    if (this.timer) {
      this.timer.unsubscribe();
      this.timer = null;
    }
    this.timerLabel = 'Start';
  }

  resetAll() {
    this.resetTime();
    this.resetColors();
  }

  resetTime() {
    this.stopTime();
    this.started = 0;
    this.time = '00:00';
    this.pausedTime = 0;
  }

  startStop() {
    if (this.timer) {
      this.stopTime();
      this.pausedTime = new Date().getTime();
    } else {
      if (!this.started) {
        this.started = new Date().getTime();
        this.pausedTime = 0;
      } else {
        if (this.pausedTime) {
          this.started += new Date().getTime() - this.pausedTime;
        }
      }

      this.timerLabel = 'Stop';
      this.timer = interval(500).subscribe((count) => {
        if (!this.timer) {
          return;
        }

        const current = new Date().getTime();

        const diff = current - this.started;
        console.log(diff);
        const min = Math.trunc(diff / 1000 / 60);
        const sec = Math.round((diff / 1000) % 60);

        const minStr = this.leadingZeros(min);
        const secStr = this.leadingZeros(sec);
        this.time = `${minStr}:${secStr}`;
      });

    }
  }

}
